import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import PrimaryButton from "../components/primary-button"
import WazokuLogoImage from "../components/wazoku-logo-image"
import PartnerCard from "../components/partner-card"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import download from "downloadjs"
import ShortlistOrganisationLogo from "../components/shortlist-logo"
import { Link } from "gatsby-plugin-modal-routing"

const EnterPage = () => (
  <Background>
    <Layout>
      <Seo title="Enter" />
      <div className="pb-5" hidden>
        <Container>
          <PageTitle text="Enter" />
          <p className="text-white text-center">Entries are now closed. Please view the <Link to="/shortlist/" className="text-white">shortlist</Link> for more information.</p>
          <p className="text-center"><PrimaryButton to="/shortlist/" text="View the Shortlist" /></p>
        </Container>
      </div>
      <div>
        <Container>
          <PageTitle text="Enter" />
          <div style={{
            textAlign: `center`,
            backgroundColor: `rgba(0,0,0,0.6)`
          }}>
            <div style={{
              maxWidth: `34rem`,
              margin: `auto`,
              padding: `5rem 2rem 1rem 2rem`
            }}>
              <span style={{
                display: `inline`,
                backgroundColor: `#d23464`,
                padding: `.5rem 0`,
                lineHeight: `3.4rem`,
                boxShadow: `.5rem 0 0 #d23464, -.5rem 0 0 #d23464`,
                textTransform: `uppercase`,
                color: `#fff`,
                fontFamily: `Din Pro`,
                fontWeight: `700`,
                fontSize: `1.6rem`
              }}>Key Dates</span>
            </div>
            <Container>
              <div className="row" style={{
                    color: `#fff`,
                    padding: `2rem`
                  }}>
                <div className="col-md" hidden>
                  <h4 style={{
                    textTransform: `uppercase`,
                    fontSize: `1.2rem`
                  }}>Entry Deadline</h4>
                  <p><s>14th June 2024</s> 19th July 2024</p>
                </div>
                <div className="col-md">
                  <h4 style={{
                    textTransform: `uppercase`,
                    fontSize: `1.2rem`
                  }}>Shortlist Pre-Release</h4>
                  <p>18th September 2024 at Conference</p>
                  <PrimaryButton url="https://events.ringcentral.com/events/smarter-working-live-2024/registration" text="Get Your Tickets" />
                </div>
                <div className="col-md">
                  <h4 style={{
                    textTransform: `uppercase`,
                    fontSize: `1.2rem`
                  }}>Shortlist Announced</h4>
                  <p>23rd September 2024</p>
                </div>
                <div className="col-md">
                  <h4 style={{
                    textTransform: `uppercase`,
                    fontSize: `1.2rem`
                  }}>Ceremony Date</h4>
                  <p>21st November 2024</p>
                </div>
              </div>
            </Container>
          </div>

          {/* <div style={{
            fontFamily: `Din Pro`,
            fontWeight: `400`,
            fontSize: `1rem`,
            color: `#fff`,
            background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
          }} hidden>
            <Container>
              <div className="row" style={{ padding: `2rem`}}>
                <div className="col-lg">             
                  <h5>Who can enter?</h5>
                  <p>We are open for nominations from across the public sector. This includes any organisation in the following:</p>
                  <ul>
                    <li>Central Government</li>
                    <li>Local Government</li>
                    <li>Healthcare</li>
                    <li>Housing</li>
                    <li>Education</li>
                    <li>Blue Light and Justice</li>
                    <li>Not-for-profit and Third Sector</li>
                  </ul>
                  <p><small><em>Private organisations are able to submit nominations for projects they have done with public sector organisations, but these must be done in partnership.</em></small></p>
                </div>
                <div className="col-lg">
                  <h5>How do I enter?</h5>
                  <p>As in previous years, we will be opening our online submission portal for accepting nominations. For anyone unable to access this platform, we will also be offering our alternative which allows you to submit by email.</p>

                  <h5>When do nominations open?</h5>
                  <p>We will be open for nominations from Monday 26th September 2022.</p>

                  <h5>Could I get a reminder?</h5>
                  <p>Please complete your details below and we will notify you when nominations open.</p>
                  
                  <form
                    id="entries"
                    name="entries"
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault()
                      let myForm = document.getElementById('entries')
                      let formData = new FormData(myForm)

                      fetch('https://hook.eu1.make.com/oc4q2v3c3wu4dqi8r38ivy7sk7ppi3w1', {
                        method: 'POST',
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: new URLSearchParams(formData).toString()
                      }).then(() => { 
                        console.log('Form successfully submitted')
                        // download(`/downloads/Smarter_Working_Live_2021_Entry_Form.docx`)
                        document.getElementById('formstatus').innerHTML = '<p>Thank you. We will send you a reminder when the nomination window has opened. We look forward to receiving your entries.</p>'
                        if(window.dataLayer) {
                          window.dataLayer.push({event: 'form-registration-download', 'gtm.elementId': 'entries', 'gtm.elementUrl': undefined});
                        }
                      }).catch((error) => alert(error))
                    }}
                  >
                    <p style={{display: `none`}}>
                      <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                    </p>
                    <input type="hidden" name="form-name" value="entries" />
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <input type="text" className="form-control" id="Email" name="email" placeholder="Email" required />
                        </div>
                      </div>
                    </div>
                    <div id="formstatus"></div>
                    <button type="submit" className="btn btn-primary" style={{
                      backgroundColor: `rgb(255, 255, 255)`,
                      borderColor: `rgb(255, 255, 255)`,
                      color: `rgb(210, 52, 100)`,
                      textTransform: `uppercase`,
                      borderRadius: 0,
                      padding: `0.6rem 1rem`,
                      fontWeight: 600,
                    }}>Submit</button>
                  </form>
                </div>
              </div>
            </Container>
          </div> */}
          {/* <div style={{
            fontFamily: `Din Pro`,
            fontWeight: `400`,
            fontSize: `1rem`,
            color: `#fff`,
            background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`
          }} hidden>
            <Container>
            <h3 className="text-center pt-5 pb-3">Previous Winners</h3>
              <div className="row" style={{ padding: `1rem 2rem 2rem 2rem`}}>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dio" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="tameside-mbc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="nhs-property" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="qehkl" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="hmrc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dfe" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="nhslpp" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="lpnt" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="wmp" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="ncc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dvla" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dwpd" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="bmbc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="nhsdigital" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="nhsbsa" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="las" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="esfa" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="hmt" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="lcc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="serc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="norfolkcc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="wcc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="ofwat" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dit" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="hee" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="bth" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="lbr" />
                  </div>
                </div>
              </div>
            </Container>
          </div> */}

          <div style={{
            fontFamily: `Din Pro`,
            fontWeight: `400`,
            fontSize: `1rem`,
            color: `#fff`,
            background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
          }}>
            <Container>
              <div className="row" style={{ padding: `2rem`}}>
                <div className="col-lg">
                  <h5>A Proud Partnership</h5>
                  <p>For Smarter Working Live, we are proud to partner with Wazoku, drivers of digital innovation, to bring you a platform to digitally submit your awards nominations.</p>
                  
                  <h5>Registration</h5>
                  <p>To enter the awards, you will need to create an account. Once you click onto ‘Enter Your Nomination’, you will be able to register for an account or login if you have already created an account. You will then be able to manage, edit or revise your entry/entries for any of the categories.</p>

                  <h5>Nomination Privacy</h5>
                  <p>All details entered in your submission will remain private and will not be disclosed. Your Project Name and Organisation Name will become public should your nomination progress to the shortlist.</p>
                </div>
                <div className="col-lg">
                  <PartnerCard title="Nomination Partner" width="12rem" style={{ float: `right`, marginLeft: `1rem`, marginBottom: `1rem` }}>
                    <OutboundLink href="https://www.wazoku.com" target="_blank" rel="noopener noreferrer"><WazokuLogoImage /></OutboundLink>
                  </PartnerCard>

                  <h5>Managing Your Entries</h5>
                  <p>You can enter as many categories as you like by completing the entry form for the category/categories that you wish to enter. If you wish to enter multiple categories, please ensure that it is relevant to the category you are entering.</p>
                  <p>You can create your nomination in draft form, allowing you to perfect your entry/entries before submission.</p>
                  <p>If you have published your entry/entries, you will still be able to edit and manage your submission, however, all entry modifications must be made before the closing date.</p>

                  <span className="mr-4"><PrimaryButton url="https://smarterworkinglive.wazoku.com" text="Login" /></span>
                  <span className="mr-4"><PrimaryButton url="https://smarterworkinglive.wazoku.com/#/register" text="Register" /></span>
                </div>
              </div>
            </Container>
          </div>



          <div style={{
            fontFamily: `Din Pro`,
            fontWeight: `400`,
            fontSize: `1rem`,
            color: `#fff`,
            background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`
          }}>
            <Container>
              <div className="row" style={{ padding: `2rem`}}>
                <div className="col-lg">
                  <h5>An Alternative Option</h5>
                  <p>If you would prefer to submit your nomination by email, please download a copy of our entry form and send a copy of your completed form along with any addtional material to entries@smarterworkinglive.com.</p>

                  <h5>Questions</h5>
                  <p>If you have any questions, please email us on <a href="mailto:entries@smarterworkinglive.com" style={{color: `#fff`}}>entries@smarterworkinglive.com</a> or call <a href="tel:+441616973438" style={{color: `#fff`}}>0161 697 3438</a>.</p>
                </div>
                <div className="col-lg">
                  {/* <a href={`/downloads/Smarter_Working_Live_2024_Entry_Form.docx`} className="btn btn-primary" style={{
                    backgroundColor: `#fff`,
                    borderColor: `#fff`,
                    color: `#d23464`,
                    textTransform: `uppercase`,
                    borderRadius: `0`,
                    padding: `.6rem 1rem`,
                    fontWeight: `600`,
                    display: `none`
                  }} download>Download Entry Form</a> */}

                  <h5>Access Entry Form</h5>
                  <p>Please enter your email address and click submit to receive access to the entry form.</p>

                  <form
                    id="entries"
                    name="entries"
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault()
                      let myForm = document.getElementById('entries')
                      let formData = new FormData(myForm)

                      fetch('https://hook.eu1.make.com/8jvqynt9cjpnzwl9nijq8ithjcckloty', {
                        method: 'POST',
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: new URLSearchParams(formData).toString()
                      }).then(() => { 
                        console.log('Form successfully submitted')
                        download(`/downloads/Smarter_Working_Live_2024_Entry_Form.docx`)
                        document.getElementById('formstatus').innerHTML = '<p>Thank you for registering and your entry form is downloading.</p>'
                        if(window.dataLayer) {
                          window.dataLayer.push({event: 'form-registration-download', 'gtm.elementId': 'entries', 'gtm.elementUrl': undefined});
                        }
                      }).catch((error) => alert(error))
                    }}
                  >
                    <p style={{display: `none`}}>
                      <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                    </p>
                    <input type="hidden" name="form-name" value="entries" />
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <input type="text" className="form-control" id="Email" name="email" placeholder="Email" required />
                        </div>
                      </div>
                    </div>
                    <div id="formstatus"></div>
                    <button type="submit" className="btn btn-primary" style={{
                      backgroundColor: `rgb(255, 255, 255)`,
                      borderColor: `rgb(255, 255, 255)`,
                      color: `rgb(210, 52, 100)`,
                      textTransform: `uppercase`,
                      borderRadius: 0,
                      padding: `0.6rem 1rem`,
                      fontWeight: 600,
                    }}>Submit</button>
                  </form>

                </div>
              </div>
            </Container>
          </div>

          <div style={{
            fontFamily: `Din Pro`,
            fontWeight: `400`,
            fontSize: `1rem`,
            color: `#fff`,
            background: `linear-gradient(180deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
            marginTop: `50px`
          }}>
            <Container>
            <h3 className="text-center pt-5 pb-3">Previous Winners</h3>
              <div className="row" style={{ padding: `1rem 2rem 2rem 2rem`}}>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dio" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="tameside-mbc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="nhs-property" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="qehkl" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="hmrc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dfe" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="nhslpp" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="lpnt" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="wmp" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="ncc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dvla" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dwpd" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="bmbc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="nhsdigital" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="nhsbsa" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="las" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="esfa" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="hmt" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="lcc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="serc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="norfolkcc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="wcc" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="ofwat" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="dit" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="hee" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="bth" />
                  </div>
                </div>
                <div className="col col-xs-4 py-2">
                  <div style={{ backgroundColor: `#fff`, borderRadius: `3px`, padding: `5px`}}>
                    <ShortlistOrganisationLogo logo="lbr" />
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div style={{marginTop: `100px`}}></div>
        </Container>
      </div>
    </Layout>
  </Background>
)

export default EnterPage
