import React from "react"

import HMRC from '../images/shortlist-logos/hmrc.png'
import DIO from '../images/shortlist-logos/dio.png'
import NSANDI from '../images/shortlist-logos/nsandi.png'
import HPCC from '../images/shortlist-logos/humberside-police.png'

import TamesideMBC from '../images/shortlist-logos/tameside-mbc.png'
import IWCouncil from '../images/shortlist-logos/iwcouncil.png'
import NHSProperty from '../images/shortlist-logos/nhs-property-services.png'
import NottinghamshireNHS from '../images/shortlist-logos/nottinghamshire-nhs.png'
import CMA from '../images/shortlist-logos/cma.png'
import DOF from '../images/shortlist-logos/dof.png'
import DFE from '../images/shortlist-logos/dfe.png'
import QEHKL from '../images/shortlist-logos/qehkl.png'
import DVLA from '../images/shortlist-logos/dvla.png'
import RHA from '../images/shortlist-logos/rha.png'
import BMBC from '../images/shortlist-logos/bmbc.png'
import CPS from '../images/shortlist-logos/cps.png'
import RCHNHS from '../images/shortlist-logos/rchnhs.png'
import COLEG from '../images/shortlist-logos/coleg.png'
import NELC from '../images/shortlist-logos/nelc.png'
import RBFB from '../images/shortlist-logos/rbfb.png'
import NHSLPP from '../images/shortlist-logos/nhslpp.png'
import NHSEMPLOYERS from '../images/shortlist-logos/nhsemployers.png'
import GMPHCL from '../images/shortlist-logos/gmphcl.png'
import LPNT from '../images/shortlist-logos/lpnt.png'
import MPNT from '../images/shortlist-logos/mpnt.png'
import YAS from '../images/shortlist-logos/yas.png'
import DVSA from '../images/shortlist-logos/dvsa.png'
import NHSSBS from '../images/shortlist-logos/nhssbs.png'
import WMP from '../images/shortlist-logos/wmp.png'
import NHSBSA from '../images/shortlist-logos/nhsbsa.png'
import NCC from '../images/shortlist-logos/ncc.png'
import SCW from '../images/shortlist-logos/scw.png'
import NSANDIGPS from '../images/shortlist-logos/nsandigps.png'
import FCO from '../images/shortlist-logos/fco.png'
import GCS from '../images/shortlist-logos/gcs.png'
import HWP from '../images/shortlist-logos/hwp.png'
import SMBC from '../images/shortlist-logos/smbc.png'
import AUHNT from '../images/shortlist-logos/auhnt.png'
import CHSNT from '../images/shortlist-logos/chsnt.png'
import DWPD from '../images/shortlist-logos/dwpd.png'
import LBB from '../images/shortlist-logos/lbb.png'
import MMO from '../images/shortlist-logos/mmo.png'
import SBC from '../images/shortlist-logos/sbc.png'
import CPCCG from '../images/shortlist-logos/cpccg.png'
import NHSDIGITAL from '../images/shortlist-logos/nhsdigital.png'
import POLICEICT from '../images/shortlist-logos/policeict.png'
import DC from '../images/shortlist-logos/dc.png'
import ELHNT from '../images/shortlist-logos/elhnt.png'
import HMCTS from '../images/shortlist-logos/hmcts.png'
import MC from '../images/shortlist-logos/mc.png'
import ABC from '../images/shortlist-logos/abc.png'
import DBS from '../images/shortlist-logos/dbs.png'
import DPC from '../images/shortlist-logos/dpc.png'
import LAS from '../images/shortlist-logos/las.png'
import SWBNT from '../images/shortlist-logos/swbnt.png'
import WCHC from '../images/shortlist-logos/wchc.png'
import ESFA from '../images/shortlist-logos/esfa.png'
import HMPO from '../images/shortlist-logos/hmpo.png'
import HMT from '../images/shortlist-logos/hmt.png'
import LCC from '../images/shortlist-logos/lcc.png'
import SERC from '../images/shortlist-logos/serc.png'
import NorfolkCC from '../images/shortlist-logos/norfolkcc.png'
import wcc from '../images/shortlist-logos/wcc.png'
import ofwat from '../images/shortlist-logos/ofwat.png'
import dit from '../images/shortlist-logos/dit.png'
import hee from '../images/shortlist-logos/hee.png'
import bth from '../images/shortlist-logos/bth.png'
import lbr from '../images/shortlist-logos/lbr.png'

// const logos = {
//   hmrc: HMRC,
//   dio: DIO,
//   nsandi: NSANDI,
//   hpcc: HPCC,
//   'tameside-mbc': TamesideMBC,
//   iwcouncil: IWCouncil,
//   'nhs-property': NHSProperty,
//   'nottinghamshire-nhs': NottinghamshireNHS,
//   cma: CMA,
//   dof: DOF,
//   dfe: DFE,
//   qehkl: QEHKL,
//   dvla: DVLA,
//   rha: RHA,
//   bmbc: BMBC,
//   cps: CPS,
//   rchnhs: RCHNHS,
//   coleg: COLEG,
//   nelc: NELC,
//   rbfb: RBFB,
//   nhslpp: NHSLPP,
//   nhsemployers: NHSEMPLOYERS,
//   gmphcl: GMPHCL,
//   lpnt: LPNT,
//   mpnt: MPNT,
//   yas: YAS,
//   dvsa: DVSA,
//   nhssbs: NHSSBS,
//   wmp: WMP,
//   nhsbsa: NHSBSA,
//   ncc: NCC,
//   scw: SCW,
//   nsandigps: NSANDIGPS,
//   fco: FCO,
//   gcs: GCS,
//   hwp: HWP,
//   smbc: SMBC,
//   auhnt: AUHNT,
//   chsnt: CHSNT,
//   dwpd: DWPD,
//   lbb: LBB,
//   mmo: MMO,
//   sbc: SBC,
//   cpccg: CPCCG,
//   nhsdigital: NHSDIGITAL,
//   policeict: POLICEICT,
//   dc: DC,
//   elhnt: ELHNT,
//   hmcts: HMCTS,
//   mc: MC,
//   abc: ABC,
//   dbs: DBS,
//   dpc: DPC,
//   las: LAS,
//   swbnt: SWBNT,
//   wchc: WCHC,
//   esfa: ESFA,
//   hmpo: HMPO,
//   hmt: HMT,
//   lcc: LCC,
//   serc: SERC,
//   norfolkcc: NorfolkCC,
//   wcc: wcc,
//   ofwat: ofwat,
//   dit: dit,
//   hee: hee,
//   bth: bth,
//   lbr: lbr
// };

const logos = {
  hmrc: { org: "HM Revenue and Customs", img: HMRC },
  dio: { org: "Defence Infrastructure Organisation", img: DIO },
  nsandi: { org: "Logo", img: NSANDI },
  hpcc: { org: "Logo", img: HPCC },
  'tameside-mbc': { org: "Tameside Metropolitan Borough Council", img: TamesideMBC },
  iwcouncil: { org: "Logo", img: IWCouncil },
  'nhs-property': { org: "NHS Property Services", img: NHSProperty },
  'nottinghamshire-nhs': { org: "Logo", img: NottinghamshireNHS },
  cma: { org: "Logo", img: CMA },
  dof: { org: "Logo", img: DOF },
  dfe: { org: "Department for Education", img: DFE },
  qehkl: { org: "The Queen Elizabeth Hospital King's Lynn NHS Foundation Trust", img: QEHKL },
  dvla: { org: "Driver and Vechicle Licensing Agency", img: DVLA },
  rha: { org: "Logo", img: RHA },
  bmbc: { org: "Barnsley Metropolitan Borough Council", img: BMBC },
  cps: { org: "Logo", img: CPS },
  rchnhs: { org: "Logo", img: RCHNHS },
  coleg: { org: "Logo", img: COLEG },
  nelc: { org: "Logo", img: NELC },
  rbfb: { org: "Logo", img: RBFB },
  nhslpp: { org: "NHS London Procurement Partnership", img: NHSLPP },
  nhsemployers: { org: "Logo", img: NHSEMPLOYERS },
  gmphcl: { org: "Logo", img: GMPHCL },
  lpnt: { org: "Leicestershire Partnership NHS Trust", img: LPNT },
  mpnt: { org: "Logo", img: MPNT },
  yas: { org: "Logo", img: YAS },
  dvsa: { org: "Logo", img: DVSA },
  nhssbs: { org: "Logo", img: NHSSBS },
  wmp: { org: "West Midlands Police", img: WMP },
  nhsbsa: { org: "NHS Business Services Authority", img: NHSBSA },
  ncc: { org: "Nottinghamshire County Council", img: NCC },
  scw: { org: "Logo", img: SCW },
  nsandigps: { org: "Logo", img: NSANDIGPS },
  fco: { org: "Logo", img: FCO },
  gcs: { org: "Logo", img: GCS },
  hwp: { org: "Logo", img: HWP },
  smbc: { org: "Logo", img: SMBC },
  auhnt: { org: "Logo", img: AUHNT },
  chsnt: { org: "Logo", img: CHSNT },
  dwpd: { org: "DWP Digital", img: DWPD },
  lbb: { org: "Logo", img: LBB },
  mmo: { org: "Logo", img: MMO },
  sbc: { org: "Logo", img: SBC },
  cpccg: { org: "Logo", img: CPCCG },
  nhsdigital: { org: "NHS Digital", img: NHSDIGITAL },
  policeict: { org: "Police ICT", img: POLICEICT },
  dc: { org: "Logo", img: DC },
  elhnt: { org: "Logo", img: ELHNT },
  hmcts: { org: "Logo", img: HMCTS },
  mc: { org: "Logo", img: MC },
  abc: { org: "Logo", img: ABC },
  dbs: { org: "Logo", img: DBS },
  dpc: { org: "Logo", img: DPC },
  las: { org: "London Ambulance Service", img: LAS },
  swbnt: { org: "Logo", img: SWBNT },
  wchc: { org: "Logo", img: WCHC },
  esfa: { org: "Eduation and Skills Funding Agency", img: ESFA },
  hmpo: { org: "Logo", img: HMPO },
  hmt: { org: "HM Treasury", img: HMT },
  lcc: { org: "Leicestershire County Council", img: LCC },
  serc: { org: "South Eastern Regional College", img: SERC },
  norfolkcc: { org: "Norfolk County Council", img: NorfolkCC },
  wcc: { org: "Westminster City Council", img: wcc },
  ofwat: { org: "Ofwat", img: ofwat },
  dit: { org: "Department for International Trade", img: dit },
  hee: { org: "Health Education England", img: hee },
  bth: { org: "Bradford Teaching Hospitals NHS Foundation Trust", img: bth },
  lbr: { org: "London Borough of Redbridge", img: lbr }
};

const ShortlistOrganisationLogo = ({ logo }) => (
  <img src={logos[logo].img} style={{ maxWidth: `75px`, maxHeight: `75px` }} alt={logos[logo].org} title={logos[logo].org} />
)

export default ShortlistOrganisationLogo
